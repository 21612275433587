<template>
  <div>
    <Header />
    <!-- <search_search /> -->
    <Search />
    <div class="big">
      <Loading v-if="isLoading" />
      <div v-else>
        <!-- <div class="w-1920">
          <div class="store-num w-1200">共{{ dian }}个店铺</div>
        </div> -->
        <div class="w-1920 conter" v-if="istrue">
          <div class="w-1200" v-for="(item, index) in list" :key="index">
            <div class="list">
              <div class="list-L">
                <div class="img">
                  <Uimage :src="item.storeImg" />
                </div>
                <div class="name">
                  {{ item.name }}
                </div>
              </div>
              <div class="list-R">
                <div class="dianpu" @click="dianpu(item)">
                  {{ $t('common.Enter_the_shop') }}
                  <i class="el-icon-arrow-right"></i>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="shangpin" @click="hidden(index)">
                  {{ item.spuBasicVoList.length }}件相关商品
                  <i v-if="activeIndex === index" class="el-icon-arrow-up"></i>
                  <i v-else class="el-icon-arrow-down"></i>
                </div>
              </div>
            </div>
            <div class="cx" v-show="activeIndex === index">
              <div class="sku" v-for="(res, i) in item.spuBasicVoList" :key="i">
                <div class="skupic" @click="detail(res.storeId, res.spuId)">
                  <Uimage :src="res.url" />
                </div>
                <div class="skuprice">
                  <!--                  <span class="sp">￥</span>{{ res.priceSection }}-->
                </div>
                <div class="skuname" @click="detail(res.storeId, res.spuId)">
                  {{ res.name }}
                </div>
                <!--                <div class="store_name">{{ item.name }}</div>-->
                <!--                <div class="skuxl">销量：{{ res.saleCount }}</div>-->
              </div>
            </div>
          </div>
          <div class="w-1920">
            <div class="fenye w-1200" v-if="list != ''">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalNum"
                @current-change="pagechange"
                :current-page.sync="currentPage"
                v-if="totalNum != 0"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div class="recommend" v-else>
          <div class="title">抱歉，没有找到与搜索相关的店铺</div>
          <div class="tuijian">推荐给您：</div>
          <div
            class="list"
            v-for="(i, index) in data"
            :key="index"
            @click="dianpu(i)"
          >
            {{ i.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="kongbai"></div>
    <Foot />
  </div>
</template>
<script>
import search_search from './search-search';
import Header from '@/components/header';
import Foot from '@/components/foot';
import { post, get } from '@/utils/request';
import Loading from '../../components/Loading.vue';
import Search from '@/components/home/search.vue';
export default {
  components: {
    Header,
    Foot,
    search_search,
    Search,
  },
  data() {
    return {
      isLoading: true,
      list: '',
      dian: '0',
      activeIndex: -1,
      currentPage: 1,
      totalNum: 1,
      q: '',
      istrue: true,
      data: [],
    };
  },
  created() {
    this.getlist(this.$route.query);
    this.q = this.$route.query.q;
  },
  methods: {
    dianpu(e) {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     storeId: e.storeId,
      //   },
      // });

      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e.storeId,
        },
      });
      window.open(href, '_blank');
    },
    hidden(index) {
      this.activeIndex = this.activeIndex == index ? -1 : index;
    },
    pagechange(p) {
      this.currentPage = p;
      this.isLoading = true;
      get(
        'f/api/frontStore/searchstore?storename=' +
          this.q +
          '&pageNum=' +
          p +
          '&pageSize=10'
      ).then((res) => {
        this.list = res.data.data.list;
        this.dian = res.data.data.total;
        this.totalNum = res.data.data.total;
        this.isLoading = false;
      });
    },
    getlist(e) {
      get(
        'f/api/frontStore/searchstore?storename=' +
          e.q +
          '&pageNum=1&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        if (res.data.msg == '操作成功') {
          this.list = res.data.data.list;
          this.dian = res.data.data.total;
          this.totalNum = res.data.data.total;
          if (res.data.data.list == '') {
            this.istrue = false;
            this.gettuijianlist();
          }
        } else {
          this.list = '';
        }
      });
    },
    gettuijianlist() {
      get('f/api/frontStore/getRecommendStore').then((res) => {
        this.data = res.data.data;
      });
    },
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.big {
  min-height: 500px;
  padding-bottom: 50px;
  .recommend {
    width: 1037px;
    background: #fff;
    margin: auto;
    padding: 40px 100px;
    .title {
      color: #ff6600;
    }

    .list {
      margin-top: 5px;
      font-size: 14px;
      color: #999;
      cursor: pointer;
    }
    .list :hover {
      color: #f66;
    }
    .tuijian {
      margin-top: 10px;
      font-size: 16px;
      color: #666;
    }
  }
}
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.store-num {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}
.conter {
  min-height: 400px;
  margin-top: 30px;
  .list {
    width: 1125px;
    height: 83px;
    padding: 16px 60px 16px 15px;
    border-bottom: 1px solid #e6e2e1;
    display: flex;
    justify-content: space-between;
    position: relative;
    .list-L {
      display: flex;
      .img {
        width: 80px;
        height: 80px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        cursor: pointer;
        font-size: 14px;
        color: #a16f66;
        margin-top: 15px;
        margin-left: 10px;
        width: 200px;
        height: 41px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .list-R {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 12px;
      text-align: center;
      .dianpu {
        width: 181px;
        height: 24px;
        background: #806f66;
        color: #fff;
        line-height: 24px;
        cursor: pointer;
      }
      .dianpu:hover {
        background: #927c6f;
      }
      .shangpin {
        width: 179px;
        height: 22px;
        background: #f7f5f5;
        border: 1px solid #eae5e1;
        color: #595959;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }
  .cx {
    margin-left: 135px;
    width: 1060px;
    height: 295px;
    border: 3px solid #e6e2e1;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    .sku {
      width: 195px;
      height: 235px;
      background: #fff;
      margin-left: 14px;
      .skupic {
        width: 160px;
        height: 160px;
        margin-left: 17px;
        margin-top: 5px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .skuprice {
        color: #ff0036;
        font-size: 15px;
        margin-top: 12px;
        margin-left: 4px;
        .sp {
          font-size: 12px;
        }
      }
      .skuname {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        width: 180px;
        height: 40px;
        margin: auto;
        margin-top: 5px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
      }
      .store_name {
        width: 180px;
        margin: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #999;
        font-size: 12px;
        margin-top: 5px;
        cursor: pointer;
      }
      .skuxl {
        color: #999;
        font-size: 12px;
        width: 180px;
        margin: auto;
        margin-top: 10px;
      }
    }
  }
}
</style>
